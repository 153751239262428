export default [
  {
    title: 'Home',
    icon: 'HomeIcon',
    route: 'dashboard-analytics',
  },
  {
    title: 'Doctor',
    icon: 'UserIcon',
    route: 'doctor-list',
  },
  {
    title: 'Patient',
    icon: 'UsersIcon',
    route: 'patient-list',
  },
  {
    title: 'Appointment',
    route: 'appointment-calendar',
    icon: 'CalendarIcon',
  },
  {
    title: 'Visit',
    route: 'visit-list',
    icon: 'FileTextIcon',
  },
]
